import { getDemonstrationExcel } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { demonstrationExcelKey } from '../../../react_query/query-keys';

const useDemonstrationExcel = () => {
    const { data, isFetching, error } = useQuery({
        queryKey: [demonstrationExcelKey],
        queryFn: () => getDemonstrationExcel()
    });

    return {
        data,
        isFetching,
    }
}

export default useDemonstrationExcel
import React from 'react'
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import useBeneficiaryChartData from 'hooks/consume_api/query/useBeneficiaryChartData';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as XLSX from 'xlsx';
import Loader from 'ui-component/Loader';

interface props {
    division: string,
    district: string,
    upazila: string,
    union: string,
    project: string
}

const Benificiary: React.FC<props> = ({ division, district, upazila, union, project }) => {
    const { data, isFetching } = useBeneficiaryChartData(division, district, upazila, union, project);
    console.log(data, "---------------------bb-----------------")
    const handleExport = (indicator: number) => {
        let tableData: any = []
        if (indicator === 1) {
            tableData = [
                {
                    "Total Beneficiary": data?.data?.male?.series[0] || 0,
                    "Male Beneficiary": data?.data?.male?.series[1] || 0,
                    "Female Beneficiary": data?.data?.female?.series[1] || 0,
                    "Youth Beneficiary": data?.data?.female?.series[1] || 0,
                }
            ];
        }
        else if (indicator === 2) {
            tableData = [
                {
                    "Particular": "Beneficiaries",
                    "Male": data?.data?.basicReport.male_count || 0,
                    "Female": data?.data?.basicReport.female_count || 0,
                    "Total": (Number(data?.data?.basicReport.male_count) + Number(data?.data?.basicReport.female_count)) || 0,
                },
                {
                    "Particular": "Youth Beneficiaries",
                    "Male": data?.data?.basicReport.male_youth || 0,
                    "Female": data?.data?.basicReport.female_youth || 0,
                    "Total": (Number(data?.data?.basicReport.male_youth) + Number(data?.data?.basicReport.female_youth)) || 0,
                },
                {
                    "Particular": "Ethnic Beneficiaries",
                    "Male": data?.data?.basicReport.male_ethnic || 0,
                    "Female": data?.data?.basicReport.female_ethnic || 0,
                    "Total": (Number(data?.data?.basicReport.male_ethnic) + Number(data?.data?.basicReport.female_ethnic)) || 0,
                },
                {
                    "Particular": "Household Head",
                    "Male": data?.data?.basicReport.male_house_hold || 0,
                    "Female": data?.data?.basicReport.female_house_hold || 0,
                    "Total": (Number(data?.data?.basicReport.male_house_hold) + Number(data?.data?.basicReport.female_house_hold)) || 0,
                },
                {
                    "Particular": "Household Member",
                    "Male": data?.data?.basicReport.house_hold_male_member || 0,
                    "Female": data?.data?.basicReport.house_hold_female_member || 0,
                    "Total": (Number(data?.data?.basicReport.house_hold_male_member) + Number(data?.data?.basicReport.house_hold_female_member)) || 0,
                },
            ];
        }
        else if (indicator === 3) {
            tableData = [
                {
                    "Land Information": "Beneficiaries",
                    "0-5 Decimal": data?.data?.landinfo.total_one || 0,
                    "6-249 Decimal": data?.data?.landinfo.total_two || 0,
                    "More than 249 Decimal": data?.data?.landinfo.total_three || 0,
                },
                {
                    "Land Information": "Youth Beneficiaries",
                    "0-5 Decimal": data?.data?.landinfo.youth_one || 0,
                    "6-249 Decimal": data?.data?.landinfo.youth_two || 0,
                    "More than 249 Decimal": data?.data?.landinfo.youth_three || 0,
                },
                {
                    "Land Information": "Ethnic Beneficiaries",
                    "0-5 Decimal": data?.data?.landinfo.ethnicity_one || 0,
                    "6-249 Decimal": data?.data?.landinfo.ethnicity_two || 0,
                    "More than 249 Decimal": data?.data?.landinfo.ethnicity_three || 0,
                },
            ];
        }

        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Beneficiary Data');
        XLSX.writeFile(wb, 'beneficiary_data.xlsx');
    };

    return (
        <> {isFetching && <Loader />}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                <Typography variant='h3'>Beneficiary</Typography>
                <Button onClick={() => handleExport(1)}>Download</Button>
            </Box>
            <Grid container >
                {data && (
                    <>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table sx={{ borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Total Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Youth Beneficiary</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[0]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[1]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.female?.series[1]}</TableCell>
                                            <TableCell>{data?.data?.youth?.series[1]}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            <Chart
                                options={{
                                    chart: {
                                        type: "pie",
                                        toolbar: {
                                            show: true,
                                            tools: {
                                                download: true,
                                            },
                                        },
                                    },
                                    labels: ["Male", "Female", "Youth"], // Combined labels
                                    title: {
                                        text: "Beneficiaries by Category",
                                    },
                                } as ApexOptions}
                                series={[
                                    ((data?.data?.male?.series[1] / data?.data?.male?.series[0]) * 100), // Male count
                                    ((data?.data?.female?.series[1] / data?.data?.female?.series[0]) * 100), // Female count
                                    ((data?.data?.youth?.series[1] / data?.data?.youth?.series[0]) * 100), // Youth count
                                ]}
                                type="pie"
                                height={300}
                            />
                        </Grid>
                        <Grid sx={{ marginTop: "20px" }} item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                                <Button onClick={() => handleExport(2)}>Download</Button>
                            </Box>

                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table sx={{ borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Particular</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Example Rows */}
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_count) + Number(data?.data?.basicReport.female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Youth beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_youth}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_youth}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_youth) + Number(data?.data?.basicReport.female_youth)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Ethnic beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_ethnic}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_ethnic}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_ethnic) + Number(data?.data?.basicReport.female_ethnic)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Household head</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_house_hold}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_house_hold}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_house_hold) + Number(data?.data?.basicReport.female_house_hold)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>Household Member</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.basicReport.house_hold_male_member}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.basicReport.house_hold_female_member}</TableCell>
                                            <TableCell>{Number(data?.data?.basicReport.house_hold_male_member) + Number(data?.data?.basicReport.house_hold_female_member)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid marginTop="10px" item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                                <Button onClick={() => handleExport(3)}>Download</Button>
                            </Box>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table sx={{ borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Land information</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>0-5 decimal</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>6-249 decimal</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>More than 249 decimal</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.total_one}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.total_two}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{data?.data?.landinfo.total_three}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Youth Beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.youth_one}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.youth_two}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{data?.data?.landinfo.youth_three}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>Ethnic Beneficiaries</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.landinfo.ethnicity_one}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.landinfo.ethnicity_two}</TableCell>
                                            <TableCell >{data?.data?.landinfo.ethnicity_three}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default Benificiary
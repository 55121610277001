import React, { useCallback, useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import {
    Autocomplete,
    Button,
    CardContent,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    TableBody,
    Pagination,
    Tooltip,
    IconButton,
    Stack,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    MenuItem
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { MemberType } from 'types/sacp';
import { useNavigate, useParams } from 'react-router-dom';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { useTheme } from '@mui/material/styles';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import { columnListNameMember, dataColumns, listDefaultData } from '../Constant';
import ServerPagingTable from 'components/Table/ServerPagingTable';
import { getMemberList, deleteInstance } from 'services/sacp';
import Loader from 'ui-component/Loader';
import useGetLocationList from 'hooks/consume_api/query/useGetLocationList';
import useAuth from 'hooks/useAuth';
import MemberDelete from './MemberDelete';
import useMemberList from 'hooks/consume_api/query/useMemberList';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import useDebounce from 'hooks/useDebounce';
import Loading from 'ui-component/Loding';
import { fileDownload, initiateMemberExport } from 'services/reactQueryservices';

const MemberRegister = () => {
    const theme = useTheme();
    const { id: projectId } = useParams();
    const navigate = useNavigate();

    const [reload, setReload] = useState<boolean>(false);
    const [projectCount, setProjectCount] = useState<number | null>(null);

    const handleReload = () => setReload(!reload);

    const [memberId, setMemberId] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    const [deletePermission, setDeletePermission] = useState<boolean>(false);

    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            user.roles.map((item, index) => {
                if (item.name === 'FAO_ADMIN') {
                    setDeletePermission(true);
                }
            });
        }
    }, []);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState<MemberType | null>(null);
    const [data, setData] = useState(listDefaultData);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const [location, setLocation] = useState<string>('-1');
    const [divisionId, setDivisionId] = useState<string>('');
    const [districtId, setDistrictId] = useState<string>('');
    const [upazilaId, setUpazilaId] = useState<string>('');
    const [unionId, setUnionId] = useState<string>('');

    const { data: divisionData } = useGetLocationList(location);
    const { data: distirctData } = useGetLocationList(divisionId);
    const { data: upazilaData } = useGetLocationList(districtId);
    const { data: unionData } = useGetLocationList(upazilaId);

    const [page, setPage] = useState<number>(1);
    const [row, setRow] = useState<number>(30);
    const [search, setSearch] = useState<string>('');
    const [finalSearch, setFinalSearch] = useState<string>('');

    const debouncedSearchValue = useDebounce(search, 500);

    const { data: memberData, isFetching: memFetch, error } = useMemberList(
        page,
        row,
        divisionId,
        districtId,
        upazilaId,
        unionId,
        debouncedSearchValue,
        projectCount ? projectCount : 0
    );

    const formId = process.env.REACT_APP_MEMBER_FORM_ID;
    const xformId = process.env.REACT_APP_MEMBER_XFORM_ID;

    const submissionUrl = `/project/${projectId}/form/${formId}/${xformId}/new-submission`;
    const editUrl = `/project/${projectId}/form/${formId}/${xformId}/edit-submission`;
    const profileUrl = `/project/${projectId}/sacp/member/profile`;

    const handleDeleteConfirm = async () => {
        await deleteInstance({ dataId: selectedData?.id });
    };

    const handleAddClick = () => {
        navigate(submissionUrl);
    };

    const handleDelete = (id: string | number) => {
        setMemberId(id.toString());
        handleOpenModal();
    };

    interface FieldOption {
        id: number;
        field_name: string;
        field_parent_id: number;
        field_type_id: number;
        geocode: string;
        geojson: object;
        uploaded_file_path: string;
        created_by: number;
        updated_by: number;
        created_date: string;
        updated_date: string;
        longitude: number | null;
        latitude: number | null;
        field_name_bn: string | null;
    }
    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const handleRowChange = (event: SelectChangeEvent) => {
        setRow(Number(event.target.value));
    };

    // useEffect(() => {
    //     if (debouncedSearchValue) setFinalSearch(debouncedSearchValue);
    // }, [debouncedSearchValue]);
    // console.log(debouncedSearchValue, 'haha');

    const designation = (is_cashier: boolean | null,
        is_president: boolean | null, is_secretary: boolean | null) => {
        if (is_cashier) return "Cashier";
        else if (is_president) return "President";
        else if (is_secretary) return "Secretary";
    }

    const handleExport = async () => {
        const fileUuid = await initiateMemberExport(districtId, divisionId, upazilaId, unionId, search);
        await fileDownload(fileUuid.data);
    }

    return (
        <>
            <MainCard
                content={false}
                contentSX={{ padding: 0 }}
                sx-={{ padding: 0 }}
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Member List</Typography>
                        </Grid>
                        <Grid item>
                            <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                                <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                                Add New Member
                            </Button>
                            <Button
                                sx={{ ml: 1 }}
                                color="secondary"
                                variant="contained"
                                onClick={handleExport}>
                                Export
                            </Button>
                        </Grid>
                    </Grid>
                }
            >
                {memFetch ? (
                    <Loader />
                ) : memberData?.data.length !== 0 ? (
                    <>
                        <Grid display="flex" justifyContent="flex-end">
                            <TextField
                                sx={{ marginTop: '5px' }}
                                id="outlined-basic"
                                label="search..."
                                variant="outlined"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Grid>
                        <CardContent>
                            <Grid container spacing={gridSpacing} marginBottom="15px">
                                <Grid item lg={3} sm={6}>
                                    <Autocomplete
                                        options={divisionData?.data as FieldOption[]}
                                        getOptionLabel={(option) => option.field_name}
                                        value={divisionData?.data.find((item: any) => item.id.toString() === divisionId) || null}
                                        onChange={(event, value) => {
                                            if (value) {
                                                setDivisionId(value?.id.toString());
                                                setDistrictId('');
                                                setUpazilaId('');
                                                setUnionId('');
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select division" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6}>
                                    <Autocomplete
                                        options={(distirctData?.data as FieldOption[]) ?? []}
                                        getOptionLabel={(option) => option.field_name}
                                        value={distirctData?.data.find((item: any) => item.id.toString() === districtId) || null}
                                        onChange={(event, value) => {
                                            if (value) {
                                                setDistrictId(value?.id.toString());
                                                setUpazilaId('');
                                                setUnionId('');
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select district" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6}>
                                    <Autocomplete
                                        options={(upazilaData?.data as FieldOption[]) ?? []}
                                        getOptionLabel={(option) => option.field_name}
                                        value={upazilaData?.data.find((item: any) => item.id.toString() === upazilaId) || null}
                                        onChange={(event, value) => {
                                            if (value) {
                                                setUpazilaId(value?.id.toString());
                                                setUnionId('');
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select upazila" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6}>
                                    <Autocomplete
                                        options={(unionData?.data as FieldOption[]) ?? []}
                                        getOptionLabel={(option) => option.field_name}
                                        value={unionData?.data.find((item: any) => item.id.toString() === unionId) || null}
                                        onChange={(event, value) => {
                                            if (value) setUnionId(value?.id.toString());
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select union" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6}>
                                    <Autocomplete
                                        options={[1, 2, 3, 4, 5]}
                                        getOptionLabel={(option) => option.toString()}
                                        value={projectCount}
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                setProjectCount(value);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Project Count" variant="outlined" />}
                                    />
                                </Grid>

                            </Grid>
                            {/* <ServerPagingTable
                            data={data?.data}
                            columns={dataColumns(
                                theme,
                                columnListNameMember,
                                editUrl,
                                profileUrl,
                                navigate,
                                setSelectedData,
                                setIsDeleteModalOpen,
                                projectId,
                                deletePermission,
                                handleDelete
                            )}
                            fetchData={fetchData}
                            divisionId={divisionId}
                            districtId={districtId}
                            upazilaId={upazilaId}
                            unionId={unionId}
                            reload={reload}
                            showSL
                        /> */}
                            <TableContainer component={Paper}>
                                <Table sx={{ width: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">#</TableCell>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">Gender</TableCell>
                                            <TableCell align="left">Group</TableCell>
                                            <TableCell align="left">MemberId</TableCell>
                                            <TableCell align="left">Address</TableCell>
                                            <TableCell align="left">Contact</TableCell>
                                            <TableCell align="left">Designation</TableCell>
                                            <TableCell align="left">Project Count</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {memberData?.data?.data.map((item: any, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell align="left">{(page - 1) * row + index + 1}</TableCell>
                                                <TableCell align="left">{item.name}</TableCell>
                                                <TableCell align="left">{item.sex}</TableCell>
                                                <TableCell align="left">{item.group}</TableCell>
                                                <TableCell align="left">{item.member_id}</TableCell>
                                                <TableCell align="left">{item.address}</TableCell>
                                                <TableCell align="left">{item.contact}</TableCell>
                                                <TableCell align="left">
                                                    {designation(item.is_cashier, item.is_president, item.is_secretary)}
                                                </TableCell>
                                                <TableCell align="left">{item.projectCount}</TableCell>
                                                <TableCell align="left">
                                                    <Stack direction="row">
                                                        <Tooltip placement="top" title="Edit">
                                                            <IconButton
                                                                color="primary"
                                                                sx={{
                                                                    color: theme.palette.warning.dark,
                                                                    borderColor: theme.palette.warning.main,
                                                                    '&:hover ': { background: theme.palette.warning.light }
                                                                }}
                                                                size="large"
                                                                onClick={() => {
                                                                    navigate(`${editUrl}/${item.id}`);
                                                                }}
                                                            >
                                                                <BorderColorTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Profile">
                                                            <IconButton
                                                                color="primary"
                                                                sx={{
                                                                    color: theme.palette.success.dark,
                                                                    borderColor: theme.palette.success.main,
                                                                    '&:hover ': { background: theme.palette.success.light }
                                                                }}
                                                                size="large"
                                                                onClick={() => {
                                                                    navigate(`${profileUrl}?instance=${item.id}&benNo=${item.member_id}&name=${item.name}&group=${item.group}&address=${item.address}&fathersname=${item.fithersname}&phone=${item.contact}`);
                                                                }}
                                                            >
                                                                <PersonOutlineIcon sx={{ fontSize: '1.3rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {deletePermission && (
                                                            <Tooltip placement="top" title="Delete">
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'red',
                                                                        borderColor: theme.palette.success.main,
                                                                        '&:hover ': { background: theme.palette.success.light }
                                                                    }}
                                                                    size="large"
                                                                    onClick={() => {
                                                                        handleDelete(item.id);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid display="flex" justifyContent="space-between" marginTop="10px">
                                <Typography variant="h4" marginTop="10px">
                                    Total : {memberData?.data.totalCount}
                                </Typography>
                                <FormControl sx={{ width: '70px' }}>
                                    <InputLabel id="demo-simple-select-label">Row</InputLabel>
                                    <Select onChange={handleRowChange} value={row.toString()}>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={Math.ceil(memberData?.data.totalCount / row)}
                                    color="primary"
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </Grid>
                        </CardContent>
                    </>
                ) : (
                    <CardContent>
                        <Typography variant="body1">No Member data found</Typography>
                    </CardContent>
                )}

                {open && <MemberDelete id={memberId} handleReload={handleReload} open={open} onClose={handleCloseModal} />}

                {/* {isDeleteModalOpen && (
                <ConfirmDeleteModal
                    open={isDeleteModalOpen}
                    setOpen={setIsDeleteModalOpen}
                    subTitle={`Delete Member '${selectedData?.name}'?`}
                    handleConfirm={handleDeleteConfirm}
                />
            )} */}
            </MainCard>
        </>
    );
};

export default MemberRegister;

import React from 'react'
import { demonstrationListKey } from 'react_query/query-keys';
import { demonstrationList } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
const useDemonstrationlist = (page: number, search: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [demonstrationListKey, page, search],
        queryFn: () => demonstrationList(page, search),
        staleTime: 300
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useDemonstrationlist;
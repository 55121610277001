import { Box, Button, Grid, Typography } from '@mui/material';
import useDemonstrationChartData from 'hooks/consume_api/query/useDemonstrationChartData'
import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as XLSX from 'xlsx';
interface props {
    division: string,
    district: string,
    upazila: string,
    union: string,
    project: string
}
const Demonstration: React.FC<props> = ({ division, district, upazila, union, project }) => {
    const { data } = useDemonstrationChartData(division, district, upazila, union, project);
    const handleExport = (indicator: number) => {
        let tableData: any = []
        if (indicator === 1) {
            tableData = [
                {
                    "Total Beneficiary": data?.data?.male?.series[0] || 0,
                    "Male Beneficiary": data?.data?.male?.series[1] || 0,
                    "Female Beneficiary": data?.data?.female?.series[1] || 0,
                    "Youth Beneficiary": data?.data?.female?.series[1] || 0, // Assuming Youth is same as Female Beneficiary for now
                }
            ];
        }
        else if (indicator === 2) {
            tableData = [
                {
                    "SubActivity": "Farmers Field trial on stress tolerant varieties/ adaptation technologies of fruits, vegetables, oilseed, cereal crops, etc. with production programme of promising technologies",
                    "Male": data?.data?.report[0].male_count || 0,
                    "Female": data?.data?.report[0].female_count || 0,
                    "Total": (Number(data?.data?.report[0].male_count) + Number(data?.data?.report[0].female_count)) || 0,
                },
                {
                    "SubActivity": "Breeder seed production of pulses, oilseeds, vegetables and other potential HVCs for BADC, seed villages, research, production programmes and demonstrations",
                    "Male": data?.data?.report[1].male_count || 0,
                    "Female": data?.data?.report[1].female_count || 0,
                    "Total": (Number(data?.data?.report[1].male_count) + Number(data?.data?.report[1].female_count)) || 0,
                },
                {
                    "SubActivity": "Farmers Field Trial on appropriate scale mechanization",
                    "Male": data?.data?.report[2].male_count || 0,
                    "Female": data?.data?.report[2].female_count || 0,
                    "Total": (Number(data?.data?.report[2].male_count) + Number(data?.data?.report[2].female_count)) || 0,
                },
            ];
        }


        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Beneficiary Data');
        XLSX.writeFile(wb, 'Demonstration_data.xlsx');
    };

    return (
        <>
            <Typography sx={{ marginTop: "40px", marginBottom: "10px" }} variant='h3'>Demonstration</Typography>
            <Grid container >
                {data && (
                    <>

                        <Grid marginTop="10px" marginBottom="10px" item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                                <Button onClick={() => handleExport(1)}>Download</Button>
                            </Box>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Total Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Youth Beneficiary</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[0]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[1]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.female?.series[1]}</TableCell>
                                            <TableCell >{data?.data?.youth?.series[1]}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                        <Grid item xs={12}>
                            <Chart
                                options={{
                                    chart: {
                                        type: "pie",
                                        toolbar: {
                                            show: true,
                                            tools: {
                                                download: true,
                                            },
                                        },
                                    },
                                    labels: ["Male", "Female", "Youth"], // Combined labels
                                    title: {
                                        text: "Demonstration by Category",
                                    },
                                } as ApexOptions}
                                series={[
                                    ((data?.data?.male?.series[1] / data?.data?.male?.series[0]) * 100), // Male count
                                    ((data?.data?.female?.series[1] / data?.data?.female?.series[0]) * 100), // Female count
                                    ((data?.data?.youth?.series[1] / data?.data?.youth?.series[0]) * 100), // Youth count
                                ]}
                                type="pie"
                                height={300}
                            />
                        </Grid>

                        <Grid marginTop="10px" item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                                <Button onClick={() => handleExport(2)}>Download</Button>
                            </Box>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>SubActivity</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Example Rows */}
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Farmers Field trial on stress tolerant varieties/ adaptation technologies of fruits, vegetables, oilseed, cereal crops, etc. with production programme of promising technologies</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[0].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[0].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[0].male_count) + Number(data?.data?.report[0].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Breeder seed production of pulses, oilseeds, vegetables and other potential HVCs for BADC, seed villages, research, production programmes and demonstrations</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[1].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[1].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[1].male_count) + Number(data?.data?.report[1].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>Farmers Field Trial on appropriate scale mechanization</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[2].male_count}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[2].female_count}</TableCell>
                                            <TableCell >{Number(data?.data?.report[2].male_count) + Number(data?.data?.report[2].female_count)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </>

                )}
            </Grid>
        </>
    )
}

export default Demonstration
import React, { useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Grid,
    Tab,
    Tabs,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Tooltip,
    Pagination,
    IconButton, SelectChangeEvent, InputLabel, Select, MenuItem, FormControl,
    TextField,
    Autocomplete,
    OutlinedInput
} from '@mui/material';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Loader from 'ui-component/Loader';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { useNavigate, useParams } from 'react-router-dom';
import useGetProjectActivityList from 'hooks/consume_api/query/useGetProjectActivityList';
import { ProjectActivityType } from 'types/sacp';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import useAuth from 'hooks/useAuth';
import useSnackbar from 'hooks/useSnackbar';
import useDeleteProject from 'hooks/consume_api/mutation/useDeleteProject';
import PromptDialog from 'views/ui-element/PromptDialog';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteDeny from './utils/DeleteDeny';
import useDebounce from 'hooks/useDebounce';
import useGetLocationList from 'hooks/consume_api/query/useGetLocationList';
import { fileDownload, initiateProjectExport } from 'services/reactQueryservices';

interface FieldOption {
    id: number;
    field_name: string;
    field_parent_id: number;
    field_type_id: number;
    geocode: string;
    geojson: object;
    uploaded_file_path: string;
    created_by: number;
    updated_by: number;
    created_date: string;
    updated_date: string;
    longitude: number | null;
    latitude: number | null;
    field_name_bn: string | null;
}
const ProjectActivityMain = () => {
    const { id: projectId } = useParams();
    const submissionUrl = `/project/${projectId}/form/160/538/new-submission`;
    const editUrl = `/project/${projectId}/form/160/538/edit-submission`;
    const profileUrl = `/project/${projectId}/sacp/project-activity/profile`;
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();
    const snackBar = useSnackbar();

    const [location, setLocation] = useState<string>('-1');
    const [divisionId, setDivisionId] = useState<string>('');
    const [districtId, setDistrictId] = useState<string>('');
    const [upazilaId, setUpazilaId] = useState<string>('');

    const [div, setDiv] = useState<string>('');
    const [dis, setDis] = useState<string>('');
    const [upa, setUpa] = useState<string>('');

    const { data: divisionData } = useGetLocationList(location);
    const { data: distirctData } = useGetLocationList(div);
    const { data: upazilaData } = useGetLocationList(dis);


    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = React.useState('');
    const [page, setPage] = useState<number>(1);
    const [row, setRow] = useState<number>(30);
    const [search, setSearch] = useState<string>('');

    const debouncedSearchValue = useDebounce(search, 500);

    const { data, isFetching } = useGetProjectActivityList(page, row, debouncedSearchValue, divisionId, districtId, upazilaId);

    const { mutate } = useDeleteProject();

    const handleAddClick = () => {
        navigate(submissionUrl);
    };

    const handleUpdate = (id: number) => {
        navigate(`${editUrl}/${id}`);
    };

    const handleProfile = (subActivity: string, subActivityId: string, date: string | null, location: string, instance: number, implementedBy: string) => {
        navigate(`${profileUrl}?subactivity=${subActivity}&subactivityId=${subActivityId}&date=${date}&location=${location}&instance=${instance}&implementedby=${implementedBy}`);
    };
    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const handleRowChange = (event: SelectChangeEvent) => {
        setRow(Number(event.target.value));
    };


    const handleDelete = (id: number | string) => {
        let admin = false;
        user?.roles?.map((role) => {
            if (role.name === "FAO_ADMIN") {
                admin = true;
            }
        })
        if (admin) {
            setDeleteId(id.toString());
            setOpen(true);
        }
        else {
            snackBar("Only admin can delete");
        }
    }

    const handleExport = async (indicator: number) => {
        const fileUuid = await initiateProjectExport(divisionId, districtId, upazilaId, debouncedSearchValue, indicator);
        await fileDownload(fileUuid.data);
    }
    return (
        <>
            {isFetching && <Loader />}
            <MainCard
                contentSX={{ padding: 0 }}
                sx-={{ padding: 0 }}
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Project Activity List</Typography>
                        </Grid>
                        <Grid item >
                            <Button
                                sx={{ ml: -1 }}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleAddClick()}
                            >
                                <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                                Add Project Activity
                            </Button>


                        </Grid>
                        <Grid sx={{ width: "200px" }}>
                            <FormControl fullWidth variant="outlined" >
                                <InputLabel htmlFor="dropdown">Export option</InputLabel>
                                <Select
                                    input={<OutlinedInput label="Select Option" id="dropdown" />}
                                >
                                    <MenuItem onClick={() => handleExport(1)} value="option1">Activity report</MenuItem>
                                    <MenuItem onClick={() => handleExport(2)} value="option2">Capacity building</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            >
                <Grid display="flex"  >

                    <Grid item lg={3} sm={6} marginLeft="10px">
                        <Autocomplete
                            options={divisionData?.data as FieldOption[]}
                            getOptionLabel={(option) => option.field_name}
                            value={divisionData?.data.find((item: any) => item.geocode.toString() === divisionId) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setDivisionId(value?.geocode.toString());
                                    setDiv(value?.id.toString());
                                    setDis("");
                                    setUpa("");
                                    setDistrictId('');
                                    setUpazilaId('');
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select division" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} marginLeft="20px">
                        <Autocomplete
                            options={(distirctData?.data as FieldOption[]) ?? []}
                            getOptionLabel={(option) => option.field_name}
                            value={distirctData?.data.find((item: any) => item.geocode.toString() === districtId) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setDistrictId(value?.geocode.toString());
                                    setDis(value?.id.toString());
                                    setUpa("");
                                    setUpazilaId('');
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select district" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} marginLeft="20px">
                        <Autocomplete
                            options={(upazilaData?.data as FieldOption[]) ?? []}
                            getOptionLabel={(option) => option.field_name}
                            value={upazilaData?.data.find((item: any) => item.geocode.toString() === upazilaId) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setUpazilaId(value?.geocode.toString());
                                    setUpa(value?.id.toString());
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select upazila" variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                <Grid display="flex" justifyContent="end">
                    <TextField onChange={(e) => setSearch(e.target.value)} value={search} id="outlined-basic" label="search" variant="outlined" />
                </Grid>
                <Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">#</TableCell>
                                    <TableCell align="left">Name of Sub-Activity</TableCell>
                                    <TableCell align="left">Implemented By</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Location</TableCell>
                                    <TableCell align="left">Member Count</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data?.data &&
                                    data?.data?.data.map((item: ProjectActivityType, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell align="left">{(page - 1) * row + index + 1}</TableCell>
                                            <TableCell align="left">{item.sub_activity}, {item.sub_activity_name}</TableCell>
                                            <TableCell align="left">{item.implemented_by}</TableCell>
                                            <TableCell align="left">{item.implementation_date}</TableCell>
                                            <TableCell align="left">{item.location}</TableCell>
                                            <TableCell align="left">{item.member_count}</TableCell>
                                            <TableCell align="left">
                                                <Grid sx={{ display: 'flex' }}>
                                                    {item.is_validate !== true && (
                                                        <Tooltip placement="top" title="Edit">
                                                            <IconButton
                                                                color="primary"
                                                                sx={{
                                                                    color: theme.palette.warning.dark,
                                                                    borderColor: theme.palette.warning.main,
                                                                    '&:hover ': { background: theme.palette.warning.light }
                                                                }}
                                                                size="large"
                                                                onClick={() => {
                                                                    navigate(`${editUrl}/${item.id}`);
                                                                }}
                                                            >
                                                                <BorderColorTwoToneIcon
                                                                    onClick={() => handleUpdate(item.id)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip title="Click to view profile" arrow>
                                                        <PersonOutlineIcon
                                                            onClick={() =>
                                                                handleProfile(
                                                                    item.sub_activity,
                                                                    item.sub_activity_type,
                                                                    item.implementation_date,
                                                                    item.location,
                                                                    item.id,
                                                                    item.implemented_by
                                                                )
                                                            }
                                                            sx={{
                                                                marginLeft: '5px',
                                                                cursor: 'pointer',
                                                                color: 'success.main',
                                                                marginTop: '12px'
                                                            }} // Change 'primary.main' to your desired color
                                                        />
                                                    </Tooltip>
                                                    {item.is_validate !== true && (
                                                        <Tooltip placement="top" title="Delete">
                                                            <DeleteTwoToneIcon
                                                                onClick={() => handleDelete(item.id)}
                                                                sx={{
                                                                    fontSize: '1.3rem',
                                                                    marginTop: '13px',
                                                                    marginLeft: '6px',
                                                                    cursor: 'pointer',
                                                                    color: 'red'
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <Grid display="flex" justifyContent="space-between" marginTop="10px">
                            <Typography>Total : {data?.data?.total}</Typography>
                            <FormControl sx={{ width: '70px' }}>
                                <InputLabel id="demo-simple-select-label">Row</InputLabel>
                                <Select onChange={handleRowChange} value={row.toString()}>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                            <Pagination
                                count={Math.ceil(data?.data?.total / row)}
                                color="primary"
                                page={page}
                                onChange={handleChangePage}
                            />
                        </Grid>
                    </TableContainer>
                </Grid>
                <PromptDialog
                    open={open}
                    setOpen={setOpen}
                    agreeAction={() => mutate(deleteId)}
                    dialogTitleText={'Remove participant'}
                    dialogContentText={'This participant will be removed. Are you sure?'}
                    disagreeText={'Cancel'}
                    agreeText={'Remove'}
                />
            </MainCard>
        </>
    );
};

export default ProjectActivityMain;

import { Box, Button, Grid, Typography } from '@mui/material';
import useProjectActivityChartData from 'hooks/consume_api/query/useProjectActivityChartData'
import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as XLSX from 'xlsx';
interface props {
    division: string,
    district: string,
    upazila: string,
    union: string
}
const ProjectActivity: React.FC<props> = ({ division, district, upazila, union }) => {
    const { data } = useProjectActivityChartData(division, district, upazila, union);
    console.log(data, "-----------------")
    const handleExport = (indicator: number) => {
        let tableData: any = [];
        if (indicator === 1) {
            tableData = [
                {
                    "SubActivity": "III TOT for DAM and other agencies Officials on Entrepreneurship Development",
                    "Male": data?.data?.report[6].male_count || 0,
                    "Female": data?.data?.report[6].female_count || 0,
                    "Total": (Number(data?.data?.report[6].male_count) + Number(data?.data?.report[6].female_count)) || 0,
                }
            ];
        }
        else if (indicator === 2) {
            tableData = [
                {
                    "SubActivity": "II Farmer Training on business management skills",
                    "Male": data?.data?.report[0].male_count || 0,
                    "Female": data?.data?.report[0].female_count || 0,
                    "Total": (Number(data?.data?.report[0].male_count) + Number(data?.data?.report[0].female_count)) || 0,
                },
                {
                    "SubActivity": "Training of the youth and women groups",
                    "Male": data?.data?.report[1].male_count || 0,
                    "Female": data?.data?.report[1].female_count || 0,
                    "Total": (Number(data?.data?.report[1].male_count) + Number(data?.data?.report[1].female_count)) || 0,
                },
                {
                    "SubActivity": "VIII Foreign Exposure Visit",
                    "Male": data?.data?.report[2].male_count || 0,
                    "Female": data?.data?.report[2].female_count || 0,
                    "Total": (Number(data?.data?.report[2].male_count) + Number(data?.data?.report[2].female_count)) || 0,
                },
                {
                    "SubActivity": "VI Farmer training on postharvest and primary processing",
                    "Male": data?.data?.report[3].male_count || 0,
                    "Female": data?.data?.report[3].female_count || 0,
                    "Total": (Number(data?.data?.report[3].male_count) + Number(data?.data?.report[3].female_count)) || 0,
                },
                {
                    "SubActivity": "V TOT for DAM officials and SAAOs on postharvest and primary processing",
                    "Male": data?.data?.report[4].male_count || 0,
                    "Female": data?.data?.report[4].female_count || 0,
                    "Total": (Number(data?.data?.report[4].male_count) + Number(data?.data?.report[4].female_count)) || 0,
                },
                {
                    "SubActivity": "IV Farmers Training on Entrepreneurship Development",
                    "Male": data?.data?.report[5].male_count || 0,
                    "Female": data?.data?.report[5].female_count || 0,
                    "Total": (Number(data?.data?.report[5].male_count) + Number(data?.data?.report[5].female_count)) || 0,
                },
                {
                    "SubActivity": "III TOT for DAM and other agencies Officials on Entrepreneurship Development",
                    "Male": data?.data?.report[6].male_count || 0,
                    "Female": data?.data?.report[6].female_count || 0,
                    "Total": (Number(data?.data?.report[6].male_count) + Number(data?.data?.report[6].female_count)) || 0,
                },
                {
                    "SubActivity": "RAINS: Establishing homestead gardens demo plots (2 per upazilla)",
                    "Male": data?.data?.report[7].male_count || 0,
                    "Female": data?.data?.report[7].female_count || 0,
                    "Total": (Number(data?.data?.report[7].male_count) + Number(data?.data?.report[7].female_count)) || 0,
                },
                {
                    "SubActivity": "RAINS: Refresher training/workshop for FFS Master Trainers (1 day, 4 batches of 30 persons)",
                    "Male": data?.data?.report[8].male_count || 0,
                    "Female": data?.data?.report[8].female_count || 0,
                    "Total": (Number(data?.data?.report[8].male_count) + Number(data?.data?.report[8].female_count)) || 0,
                }
            ];
        }


        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Beneficiary Data');
        XLSX.writeFile(wb, 'Project_activity_data.xlsx');
    };

    return (
        <>
            <Typography sx={{ marginTop: "10px", marginBottom: "10px" }} variant='h3'>Project Activity Chart</Typography>
            <Grid container >
                {data && (
                    <>
                        <Grid marginTop="10px" marginBottom="10px" item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                                <Button onClick={() => handleExport(1)}>Download</Button>
                            </Box>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Sub activity</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>III TOT for DAM and other agencies Officials on Entrepreneurship Development</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[6].male_count}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[6].female_count}</TableCell>
                                            <TableCell >{Number(data?.data?.report[6].male_count) + Number(data?.data?.report[6].female_count)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                        <Grid item xs={12}>
                            <Chart
                                options={{
                                    chart: {
                                        type: "pie",
                                        toolbar: {
                                            show: true,
                                            tools: {
                                                download: true,
                                            },
                                        },
                                    },
                                    labels: ["Male", "Female", "Youth"],
                                    title: {
                                        text: "Project activity by Category",
                                    },
                                } as ApexOptions}
                                series={[
                                    ((data?.data?.male?.series[1] / data?.data?.male?.series[0]) * 100),
                                    ((data?.data?.female?.series[1] / data?.data?.female?.series[0]) * 100),
                                    ((data?.data?.youth?.series[1] / data?.data?.youth?.series[0]) * 100),
                                ]}
                                type="pie"
                                height={300}
                            />
                        </Grid>

                        <Grid marginTop="10px" item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                                <Button onClick={() => handleExport(2)}>Download</Button>
                            </Box>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>SubActivity</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>II Farmer Training on business management skills</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[0].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[0].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[0].male_count) + Number(data?.data?.report[0].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Training of the youth and women groups</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[1].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[1].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[1].male_count) + Number(data?.data?.report[1].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>VIII Foreign Exposure Visit</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[2].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[2].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[2].male_count) + Number(data?.data?.report[2].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>VI Farmer training on postharvest and primary processing</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[3].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[3].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[3].male_count) + Number(data?.data?.report[3].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>V TOT for DAM officials and SAAOs on postharvest and primary processing</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[4].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[4].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[4].male_count) + Number(data?.data?.report[4].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>IV Farmers Training on Entrepreneurship Development</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[5].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[5].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[5].male_count) + Number(data?.data?.report[5].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>III TOT for DAM and other agencies Officials on Entrepreneurship Development</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[6].male_count}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[6].female_count}</TableCell>
                                            <TableCell >{Number(data?.data?.report[6].male_count) + Number(data?.data?.report[6].female_count)}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>RAINS: Establishing homestead gardens demo plots (2 per upazilla)</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[7].male_count}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[7].female_count}</TableCell>
                                            <TableCell >{Number(data?.data?.report[7].male_count) + Number(data?.data?.report[7].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>RAINS: Refresher training/workshop for FFS Master Trainers (1 day, 4 batches of 30 persons)</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[8].male_count}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[8].female_count}</TableCell>
                                            <TableCell >{Number(data?.data?.report[8].male_count) + Number(data?.data?.report[8].female_count)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default ProjectActivity
import useInfrastructureChart from 'hooks/consume_api/query/useInfrastructureChart'
import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as XLSX from 'xlsx';
interface props {
    division: string,
    district: string,
    upazila: string,
    union: string
}
const Infrastructure: React.FC<props> = ({ division, district, upazila, union }) => {
    const { data } = useInfrastructureChart();
    const handleExport = (indicator: number) => {
        let tableData: any = [];
        if (indicator === 1) {
            tableData = [
                {
                    "SubActivity": "Beneficiaries",
                    "Male": data?.data[0].male_count || 0,
                    "Female": data?.data[0].female_count || 0,
                    "Total": (Number(data?.data[0].male_count) + Number(data?.data[0].female_count)) || 0,
                },
                {
                    "SubActivity": "Beneficiaries",
                    "Male": data?.data[1].male_count || 0,
                    "Female": data?.data[1].female_count || 0,
                    "Total": (Number(data?.data[1].male_count) + Number(data?.data[1].female_count)) || 0,
                }
            ];

        }

        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Beneficiary Data');
        XLSX.writeFile(wb, 'Project_activity_data.xlsx');
    };
    return (
        data?.data?.length ? (
            <>
                <Typography variant='h3' sx={{ marginTop: "20px" }}>Infrastructure Report</Typography>
                <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                    <Button onClick={() => handleExport(1)}>Download</Button>
                </Box>
                <Grid marginTop="10px" item xs={12}>
                    <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Sub Activity</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Establish Common Facility Center on cost sharing</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data[0].male_count}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data[0].female_count}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data[0].male_count) + Number(data?.data[0].female_count)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderRight: '1px solid black' }}>Solar Pump Based Solar home system ( Pumpset with Accessories ) supply with Installation</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data[1].male_count}</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data[1].female_count}</TableCell>
                                    <TableCell >{Number(data?.data[1].male_count) + Number(data?.data[1].female_count)}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </>
        ) : null
    )

}

export default Infrastructure